import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;
const PROJECTID = ServerConfiguration.PROJECTID;
const initialState = {
  isLoading: false,
  error: null,
  promoList: [],
  promoListByID: [],


  promoStatus: null,
  deletePromo: null,
  addPromo: null,
  updatePromo: null,
};

const slice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {

    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // View Promotion List
    getPromotionListingSuccess(state, action) {
      state.isLoading = false;
      state.promoList = action.payload;
    },

    getPromotionListingByIDSuccess(state, action) {
      state.isLoading = false;
      state.promoListByID = action.payload;
    },

    updatePromotionStatusSuccess(state, action) {
      state.isLoading = false;
      state.promoStatus = action.payload;
    },

    deletePromotionSuccess(state, action) {
      state.isLoading = false;
      state.deletePromo = action.payload;
    },
    
    addPromotionSuccess(state, action) {
      state.isLoading = false;
      state.addPromo = action.payload;
    },
    
    updatePromotionSuccess(state, action) {
      state.isLoading = false;
      state.updatePromo = action.payload;
    },

    clearPromotionActionSuccess(state, action) {
      state.isLoading = false;
      state.promoStatus = null;
      state.deletePromo = null;
      state.addPromo = null;
      state.updatePromo = null;
      state.error = null;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  Category_ViewList,
} = slice.actions;

// ----------------------------------------------------------------------


export function getPromotionListing(isEnable, userId, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_ViewPromotion?ACTIVEIND=${isEnable}&PROJECTID=${PROJECTID}&USERID=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getPromotionListingSuccess(returndata));
      } else {
        dispatch(slice.actions.getPromotionListingSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPromotionListingByID(promotionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_ViewPromotionByID?PROMOTIONID=${promotionId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getPromotionListingByIDSuccess(returndata));
      } else {
        dispatch(slice.actions.getPromotionListingByIDSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePromotionStatus(promotionId, isEnable, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_UpdatePromotionActiveInd?PROMOTIONID=${promotionId}&ACTIVEIND=${isEnable}&USERID=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.updatePromotionStatusSuccess(returndata));
      } else {
        dispatch(slice.actions.updatePromotionStatusSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePromotion(promotionId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_DeletePromotion?PROMOTIONID=${promotionId}&USERID=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.deletePromotionSuccess(returndata));
      } else {
        dispatch(slice.actions.deletePromotionSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function addPromotion(promo) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_AddPromotion?PROMOTIONTITLE=${promo.title}&PROJECTID=${PROJECTID}&PROMOTIONDESC=${promo.description}&PROMOTIONSTARTDATE=${promo.beginDate}&PROMOTIONENDDATE=${promo.endDate}&BANNERIMAGE=${promo.banner}&SLIDEORDER=${promo.slideOrder}&PRODUCTID=${promo.productId}&PRODUCTDISCOUNT=${promo.productDiscount}&PRODUCTSTOCKLIMIT=${promo.stockLimitQty}&PRODUCTVARIATIONDETAILID=${promo.productVariationDetailID}&PRODUCTPURCHASELIMIT=${promo.purchaseLimit}&ACTIVEIND=${promo.isEnable}&APPLYSTARTDATE=${promo.applyStartDate}&APPLYENDDATE=${promo.applyEndDate}&ACCESSCATEGORYID=${promo.accessCategoryId}&USERID=${promo.userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.addPromotionSuccess(returndata));
      } else {
        dispatch(slice.actions.addPromotionSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePromotion(promo) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_UpdatePromotion?PROMOTIONID=${promo.promotionId}&DELETEDPROMOTIONPRODUCTID=${promo.deletedProductId}&UPDATEDPROMOTIONPRODUCTID=${promo.updatedProductID}&PROMOTIONTITLE=${promo.title}&PROMOTIONDESC=${promo.description}&PROMOTIONSTARTDATE=${promo.beginDate}&PROMOTIONENDDATE=${promo.endDate}&BANNERIMAGE=${promo.banner}&SLIDEORDER=${promo.slideOrder}&PRODUCTID=${promo.productId}&PRODUCTDISCOUNT=${promo.productDiscount}&PRODUCTSTOCKLIMIT=${promo.stockLimitQty}&PRODUCTVARIATIONDETAILID=${promo.productVariationDetailID}&PRODUCTPURCHASELIMIT=${promo.purchaseLimit}&ACTIVEIND=${promo.isEnable}&APPLYSTARTDATE=${promo.applyStartDate}&APPLYENDDATE=${promo.applyEndDate}&USERID=${promo.userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.updatePromotionSuccess(returndata));
      } else {
        dispatch(slice.actions.updatePromotionSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearPromotionAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearPromotionActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



// ----------------------------------------------------------------------

