import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock/arrays';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { useAuthContext } from '../../../auth/useAuthContext';
import { useDispatch, useSelector } from '../../../redux/store';
import {
  viewNotificationByID,
  clearUserAction,
  updateNotificationStatus,
} from '../../../redux/slices/user';

import NotificationsDetails from './NotificationsDetails';
// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openPopover, setOpenPopover] = useState(null);

  const { notification } = useSelector((state) => state.user);
  const [notifications, setNotifications] = useState([]);
  const totalUnRead = notification.filter((item) => item.NotificationStatus === 'New').length;

  useEffect(() => {
    if (user && user?.UserID) {
      dispatch(viewNotificationByID(user?.UserID));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (notification && notification.length > 0) {
      setNotifications(notification.slice(0, 7));
    }
  }, [notification]);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };


  return (
    <>
      {
        user?.UserTypeID <= 16 &&
        <IconButtonAnimate
          color={openPopover ? 'primary' : 'default'}
          onClick={handleOpenPopover}
          sx={{ width: 40, height: 40 }}
        >
          <Badge badgeContent={totalUnRead} color="error">
            <Iconify icon="eva:bell-fill" />
          </Badge>
        </IconButtonAnimate>
      }


      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <NotificationsDetails
          handleClosePopover={handleClosePopover}
          newNotifications={notifications.slice(0, 2)}
          oldNotifications={notifications.slice(2, 5)}
          totalUnRead={totalUnRead}
        />
      </MenuPopover>
    </>
  );
}
