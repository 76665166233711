import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;
const PROJECTID = ServerConfiguration.PROJECTID;
const initialState = {
  isLoading: false,
  error: null,
  newsList: null,
  updateNewsAction: null,
  deleteNewsAction: null,
  addNewsAction: null,
  updateNewsStatusAction : null
};

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    viewNewsSuccess(state, action) {
      state.isLoading = false;
      state.newsList = action.payload;
    },

    addNewsSuccess(state, action) {
      state.isLoading = false;
      state.addNewsAction = action.payload;
    },

    updateNewsSuccess(state, action) {
      state.isLoading = false;
      state.updateNewsAction = action.payload;
    },

    deleteNewsSuccess(state, action) {
      state.isLoading = false;
      state.deleteNewsAction = action.payload;
    },

    updateNewsStatusSuccess(state, action) {
      state.isLoading = false;
      state.updateNewsStatusAction = action.payload;
    },

    clearAllAction(state, action) {
      state.isLoading = false;
      state.addNewsAction = null;
      state.updateNewsAction = null;
      state.deleteNewsAction = null;
      state.updateNewsStatusAction = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  clearAllAction
} = slice.actions;

// ----------------------------------------------------------------------

export function viewNews(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}ViewLatestNewsListingByMerchantID?MERCHANTID=${userId}&PROJECTID=${PROJECTID}&ISADMINVIEW=1`
      );

      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.viewNewsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.viewNewsSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addNews(newsData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}News_AddNews?MODIFYBY=${newsData.userId}&PROJECTID=${PROJECTID}&NEWSTITLE=${newsData.title}&NEWSURL=${newsData.redirecturl}&NEWSMEDIA=${newsData.image}&NEWSMEDIATYPE=${newsData.mediaType}&NEWSDESCRIPTION=${newsData.description}&ISREQUIRENAVIGATION=${newsData.isRequiredButton}&ISREQUIRETITLE=${newsData.isRequiredTitle}&ISREQUIREDESC=${newsData.isRequiredDesc}`
      );

      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addNewsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addNewsSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateNews(newsData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}News_UpdateNews?MODIFYBY=${newsData.userId}&NEWSID=${newsData.newsId}&NEWSTITLE=${newsData.title}&NEWSURL=${newsData.redirecturl}&NEWSMEDIA=${newsData.image}&NEWSMEDIATYPE=${newsData.mediaType}&NEWSDESCRIPTION=${newsData.description}&ACTIVEIND=${newsData.activeInd}&ISREQUIRENAVIGATION=${newsData.isRequiredButton}&ISREQUIRETITLE=${newsData.isRequiredTitle}&ISREQUIREDESC=${newsData.isRequiredDesc}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateNewsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateNewsSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function deleteNews(userId, newsId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}News_DeleteNews?MODIFYBY=${userId}&NEWSID=${newsId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteNewsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteNewsSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateNewsStatus(userId, newsId, activeInd) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}News_UpdateNewsStatus?MODIFYBY=${userId}&NEWSID=${newsId}&ACTIVEIND=${activeInd}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateNewsStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateNewsStatusSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
