import { createSlice } from '@reduxjs/toolkit';
// utils
import uploader from '../../utils/imgUploader';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl
const PROJECTID = ServerConfiguration.PROJECTID

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  promoListByID: [],
  promoStatus: null,
  addPromo: null,
  updatePromo: null,
  deletePromo: null,
};

const slice = createSlice({
  name: 'accommodationPromotion',
  initialState,
  reducers: {
    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get Promotion Listing by Hotel ID
    getPromotionListingByIDSuccess(state, action) {
      state.isLoading = false;
      state.promoListByID = action.payload;
    },

    updatePromotionStatusSuccess(state, action) {
      state.isLoading = false;
      state.promoStatus = action.payload;
    },

    // Add Promotion
    addPromotionSuccess(state, action) {
      state.isLoading = false;
      state.addPromo = action.payload;
    },

    // Update Promotion
    updatePromotionSuccess(state, action) {
      state.isLoading = false;
      state.updatePromo = action.payload;
    },

    // Delete Promotion
    deletePromotionSuccess(state, action) {
      state.isLoading = false;
      state.deletePromo = action.payload;
    },

    clearPromotionActionSuccess(state, action) {
      state.isLoading = false;
      state.promoStatus = null;
      state.deletePromo = null;
      state.addPromo = null;
      state.updatePromo = null;
      state.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  clearPromotionActionSuccess
} = slice.actions;

// ----------------------------------------------------------------------

export function getPromotionListingByID(activeInd, hotelId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_ViewPromotionByHotelID?ACTIVEIND=${activeInd}&HOTELID=${hotelId}&USERID=${userId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getPromotionListingByIDSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePromotionStatus(promotionId, isEnable, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_UpdatePromotionActiveInd?PROMOTIONID=${promotionId}&ACTIVEIND=${isEnable}&USERID=${userId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.updatePromotionStatusSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPromotion(userId, name, description, startDate, endDate, categoryId, applyStartDate, applyEndDate, bannerImageName, slideOrder, hotelId, roomId, promotionId, discountRate, rateType, noOfFreeNight, discountType, minRoom, minNight, nightSpec, imageFile) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await uploader([imageFile], [bannerImageName], 'accommodationPromotion', hotelId)

      if (!resp) {
        dispatch(slice.actions.hasError('Failed to upload image'));
        return;
      }

      const response = await fetch(
        `${url}Promo_AddHotelPromotion?USERID=${userId}&PROMOTIONTITLE=${name}&ProjectID=${PROJECTID}&PROMOTIONDESC=${description}&PROMOTIONSTARTDATE=${startDate}&PROMOTIONENDDATE=${endDate}&ACCESSCATEGORYID=${categoryId}&APPLYSTARTDATE=${applyStartDate}&APPLYENDDATE=${applyEndDate}&BANNERIMAGE=${bannerImageName}&SLIDEORDER=${slideOrder}&HOTELID=${hotelId}&ROOMID=${roomId}&TYPEOFPROMOTION=${promotionId}&DISCOUNTRATE=${discountRate}&DISCOUNTRATETYPE=${rateType}&FREENIGHTNUM=${noOfFreeNight}&DISCOUNTTYPE=${discountType}&MINROOM=${minRoom}&MINNIGHT=${minNight}&NIGHTSPECIFICATION=${nightSpec}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.addPromotionSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePromotion(promotionId, userId, name, description, bannerImageName, slideOrder, promoStartDate, promoEndDate, applyStartDate, applyEndDate, promotionItemId, hotelId, roomId, promotionType, discountRate, rateType, noOfFreeNight, discountType, minRoom, minNight, nightSpec, activeInd, imageFile) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (typeof imageFile === 'object') {
        const resp = await uploader([imageFile], [bannerImageName], 'accommodationPromotion', hotelId)

        if (!resp) {
          dispatch(slice.actions.hasError('Failed to upload image'));
          return;
        }
      }

      const response = await fetch(
        `${url}Promo_UpdateHotelPromotion?PROMOTIONID=${promotionId}&USERID=${userId}&PROMOTIONTITLE=${name}&PROMOTIONDESC=${description}&BANNERIMAGE=${bannerImageName}&SLIDEORDER=${slideOrder}&PROMOTIONSTARTDATE=${promoStartDate}&PROMOTIONENDDATE=${promoEndDate}&APPLYSTARTDATE=${applyStartDate}&APPLYENDDATE=${applyEndDate}&PROMOTIONITEMID=${promotionItemId}&HOTELID=${hotelId}&ROOMID=${roomId}&TYPEOFPROMOTION=${promotionType}&DISCOUNTRATE=${discountRate}&DISCOUNTRATETYPE=${rateType}&FREENIGHTNUM=${noOfFreeNight}&DISCOUNTTYPE=${discountType}&MINROOM=${minRoom}&MINNIGHT=${minNight}&NIGHTSPECIFICATION=${nightSpec}&ACTIVEIND=${activeInd}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.updatePromotionSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePromotion(promotionId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Promo_DeletePromotion?PROMOTIONID=${promotionId}&USERID=${userId}`
      )
      const json = await response.json();
      if (json.Message) {
        dispatch(slice.actions.hasError(json.Message));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.deletePromotionSuccess(JSON.parse(data.ReturnData)[0]));
        } else {
          dispatch(slice.actions.hasError(data.ReturnSqlError));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearPromotionAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearPromotionActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}