import { createSlice } from '@reduxjs/toolkit';
// utils
import { ServerConfiguration } from '../../utils/serverConfig';
import { setUser } from '../../auth/utils';
// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;

const initialState = {
  isLoading: false,
  error: null,
  shopProfile: null,
  acountProfile: null,
  openModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'accountShopInfo',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateAccountShopInfoSuccess(state, action) {
      state.isLoading = false;
      state.shopProfile = action.payload;
    },

    updateAccountProfileSuccess(state, action) {
      state.isLoading = false;
      state.acountProfile = action.payload;
    },


    clearProfileAction(state, action) {
      state.isLoading = false;
      state.shopProfile = null;
      state.acountProfile = null;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const {
  clearProfileAction,
} = slice.actions;
// ----------------------------------------------------------------------

export function updateAccountShopInfo(Data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_UpdateShopDetail?USERID=${Data.UserID}&SHOPNAME=${Data.shopName}&SHOPDESC=${Data.shopDescription}&SHOPCOUNTRYID=${Data.countryId}&SHOPSTATE=${Data.state}&SHOPCITY=${Data.city}&SHOPPOSCODE=${Data.poscode}&SHOPCOVERIMAGE=${Data.shopCoverImage}&SHOPIMAGE=${Data.shopImage}`
      );

      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        setUser(data.ReturnData);
        dispatch(slice.actions.updateAccountShopInfoSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.updateAccountShopInfoSuccess(data.ReturnMsg));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateAccountProfile(Data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_UpdateMerchantProfile?USERID=${Data.UserID}&FIRSTNAME=${Data.firstname}&LASTNAME=${Data.lastname}&USERGENDER=${Data.gender}&USERDOB=${Data.dob}&USERNRIC=${Data.nric}&USERCONTACTNO=${Data.contactnumber}&USEREMAIL=${Data.email}&SHOPBANK=${Data.shopBank}&SHOPBANKACCOUNTNAME=${Data.bankAccountName}&SHOPBANKACCOUNTNO=${Data.bankAccountNumber}&SHOPBANKACCOUNTHEADER=${Data.bankHeaderImage}&USERPROFILE=${Data.profileImage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if(data && data.ReturnVal === 1){
        setUser(data.ReturnData);
        dispatch(slice.actions.updateAccountProfileSuccess(JSON.parse(data.ReturnData)[0]));
      }else {
        dispatch(slice.actions.updateAccountProfileSuccess(data.ReturnMsg));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
