import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';

import { useCallback, useEffect, useMemo , useRef,useState } from 'react';

RHFAutocompleteWithOnChange.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,  
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  setSelectedData: PropTypes.func, 
  settingValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
};

export default function RHFAutocompleteWithOnChange({ name, label, helperText, setSelectedData,
  optionLabel = 'label',
  optionValue = 'value',
  settingValue,
  ...other }) {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if(settingValue){      
      setValue(name, settingValue, { shouldValidate: true })       
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => {
            if(newValue){              
            setSelectedData(newValue[`${optionValue}`])
            setValue(name, newValue[`${optionValue}`], { shouldValidate: true })
            }else{
              setSelectedData(null)
              setValue(name,null)
            }
          }}
          renderInput={(params) => (
            <>
            <TextField
              label={label}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
            />
            </>
          )}
          {...other}
        />
        
      )}
    />
  );
}