import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_ERROR_LOGIN, PATH_AFTER_LOGIN } from '../config-global';// auth
import { useAuthContext } from '../auth/useAuthContext';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  NotificationPage,

  // Category
  CategoryPage,

  // Analytic Report: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // Analytic Report: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,

  // News
  NewsListPage,

  // School
  ProductSchoolListPage,

  // Analytic Report: Ecommerce
  EcommerceShopPage,
  EcommerceShopProfile,
  EcommerceCheckoutPage,
  ProductEditPage,
  ProductCreatePage,
  EcommerceProductDetailsPage,


  // Product
  ProductDashboard,
  ProductListPage,
  ProductEndorsementDetailsPage,
  ProductDetailsPage,
  ProductCalendarPage,

  // Product Stock
  ProductStockListPage,
  ProductStockCreatePage,
  ProductStockEditPage,

  // Order
  OrderListPage,
  OrderReportPage,
  OrderDetailsPage,

  // Promotion
  PromotionListPage,
  PromotionCreatePage,
  PromotionEditPage,

  // Setting
  CategoryListPage,

  // Accomodation
  AccomodationListPage,
  AccomodationCalendarPage,
  AccomodationRoomListPage,
  AccomodationCreatePage,
  AccomodationRoomCreatePage,
  AccomodationEditPage,
  AccomodationRoomEditPage,
  AccomodationSettingsPage,
  AccomodationBookingListPage,
  AccommodationPromotionListPage,
  AccommodationPromotionEditPage,
  AccommodationPromotionCreatePage,
  AccommodationPropertyListPage,
  // Analytic Report: Invoice
  StatementListPage,
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Analytic Report: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Analytic Report: FileManager
  FileManagerPage,
  // Analytic Report: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  PageRestrict,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  DemoMarkdownPage,
  AccomodationBookingDetail,
  AccomodationDashboard,
} from './elements';


// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useAuthContext();

  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    // Analytic Report
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={user && user?.UserTypeID <= 16 ? PATH_AFTER_LOGIN : PATH_AFTER_ERROR_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        { path: 'ecommerce', element: <GeneralEcommercePage /> },
        { path: 'analytics', element: <GeneralAnalyticsPage /> },
        { path: 'banking', element: <GeneralBankingPage /> },
        { path: 'booking', element: <GeneralBookingPage /> },
        { path: 'file', element: <GeneralFilePage /> },
        { path: 'notificationList', element: <NotificationPage /> },        
        
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/e-commerce/shop" replace />, index: true },

            { path: 'statement', element: <StatementListPage /> },
            { path: 'shop', element: <EcommerceShopPage /> },
            { path: 'shopprofile', element: <EcommerceShopProfile /> },
            { path: 'product/:id', element: <EcommerceProductDetailsPage /> },
            
            // { path: 'list', element: <ProductListPage /> },
            // { path: 'product/new', element: <EcommerceProductCreatePage /> },
            // { path: 'product/:id/edit', element: <EcommerceProductEditPage /> },
            { path: 'checkout', element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: 'product',
          children: [
            { element: <Navigate to="/product/list" replace />, index: true },
            { path: 'dashboard', element: <ProductDashboard /> },
            { path: 'list', element: <ProductListPage /> },
            { path: 'new', element: <ProductCreatePage /> },
            { path: ':id/edit', element: <ProductEditPage /> },
            { path: ':id/endorsement', element: <ProductEndorsementDetailsPage /> },
            { path: ':id/view', element: <ProductDetailsPage /> },
            { path: 'userList', element: <UserListPage /> },
            { path: 'calendar', element: <ProductCalendarPage /> },

          ],
        },
        {
          path: 'stock',
          children: [
            { element: <Navigate to="/stock/list" replace />, index: true },
            { path: 'list', element: <ProductStockListPage /> },
            { path: 'new', element: <ProductStockCreatePage /> },
            { path: ':variationDetailId/:productId/:storageId/edit', element: <ProductStockEditPage /> },
          ],
        },
        {
          path: 'order',
          children: [
            { element: <Navigate to="/order/list" replace />, index: true },
            { path: 'list', element: <OrderListPage /> },
            { path: '/order/report/:id', element: <OrderReportPage /> },
            { path: '/order/:id', element: <OrderDetailsPage /> },
            // { path: 'new', element: <ProductStockCreatePage /> },              
            // { path: ':variationDetailId/:storageId/edit', element: <ProductStockEditPage /> },   
          ],
        },
        {
          path: 'promotion',
          children: [
            { element: <Navigate to="/promotion/list" replace />, index: true },
            { path: 'list', element: <PromotionListPage /> },
            { path: '/promotion/new', element: <PromotionCreatePage /> },
            { path: '/promotion/:id/edit', element: <PromotionEditPage /> },
            // { path: 'new', element: <ProductStockCreatePage /> },              
            // { path: ':variationDetailId/:storageId/edit', element: <ProductStockEditPage /> },   
          ],
        },
        {
          path: 'setting',
          children: [
            { element: <Navigate to="/setting/category" replace />, index: true },
            { path: 'category', element: <CategoryListPage /> },
            // { path: '/promotion/new', element: <PromotionCreatePage /> }, 
            // { path: '/promotion/:id/edit', element: <PromotionEditPage /> },      
            // { path: 'new', element: <ProductStockCreatePage /> },              
            // { path: ':variationDetailId/:storageId/edit', element: <ProductStockEditPage /> },   
          ],
        },
        {
          path: 'accomodation',
          children: [
            { element: <Navigate to="/accomodation/list" replace />, index: true },
            { path: 'dashboard', element: <AccomodationDashboard /> },
            { path: 'list', element: <AccomodationListPage /> },
            { path: 'hotel/:id/roomList', element: <AccomodationRoomListPage /> },
            { path: 'hotel/:id/new', element: <AccomodationCreatePage /> },
            { path: 'hotel/:id/edit', element: <AccomodationEditPage /> },
            { path: 'hotel/:id/room/:roomId/new', element: <AccomodationRoomCreatePage /> },
            { path: 'hotel/:id/room/:roomId/edit', element: <AccomodationRoomEditPage /> },
            { path: 'hotel/calendar', element: <AccomodationCalendarPage /> },
            { path: 'hotel/:id/setting', element: <AccomodationSettingsPage /> },
            { path: 'bookingList', element: <AccomodationBookingListPage /> },
            { path: 'booking/:id', element: <AccomodationBookingDetail /> },
            { path: 'property', element: <AccommodationPropertyListPage /> },
            { path: 'property/:id/promotion', element: <AccommodationPromotionListPage /> },
            { path: 'property/:id/promotion/:promoId', element: <AccommodationPromotionEditPage /> },
            { path: 'property/:id/promotion/:promoId/edit', element: <AccommodationPromotionEditPage /> },
            { path: 'property/:id/promotion/new', element: <AccommodationPromotionCreatePage /> },
            { path: 'userList', element: <UserListPage /> },
            // { path: ':id/edit', element: <ProductEditPage /> },
            // { path: ':id/endorsement', element: <ProductEndorsementDetailsPage /> },
            // { path: ':id/view', element: <ProductDetailsPage /> },          
          ],
        },
        {
          path: 'user',
          children: [
            // { element: <Navigate to="/user/profile" replace />, index: true },
            { element: <Navigate to="/user/list" replace />, index: true },
            
            { path: 'userList', element: <UserListPage /> },
            // { path: 'profile', element: <UserProfilePage /> },
            // { path: 'cards', element: <UserCardsPage /> },
            // { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'news',
          children: [
            // { element: <Navigate to="/news/newsList" replace />, index: true },
            { path: 'newsList', element: <NewsListPage /> },
          ],
        },
        {
          path: 'school',
          children: [
            { element: <Navigate to="/school/list" replace />, index: true },
            { path: 'schoolList', element: <ProductSchoolListPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPostsPage /> },
            { path: 'post/:title', element: <BlogPostPage /> },
            { path: 'new', element: <BlogNewPostPage /> },
          ],
        },
        { path: 'files-manager', element: <FileManagerPage /> },
        { path: 'category-management', element: <CategoryPage /> },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <MailPage /> },
            { path: 'label/:customLabel/:mailId', element: <MailPage /> },
            { path: ':systemLabel', element: <MailPage /> },
            { path: ':systemLabel/:mailId', element: <MailPage /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
        { path: 'calendar', element: <CalendarPage /> },
        { path: 'kanban', element: <KanbanPage /> },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },
    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <FaqsPage /> },
        // Demo Components
        {
          path: 'components',
          children: [
            { element: <ComponentsOverviewPage />, index: true },
            {
              path: 'foundation',
              children: [
                { element: <Navigate to="/components/foundation/colors" replace />, index: true },
                { path: 'colors', element: <FoundationColorsPage /> },
                { path: 'typography', element: <FoundationTypographyPage /> },
                { path: 'shadows', element: <FoundationShadowsPage /> },
                { path: 'grid', element: <FoundationGridPage /> },
                { path: 'icons', element: <FoundationIconsPage /> },
              ],
            },
            {
              path: 'mui',
              children: [
                { element: <Navigate to="/components/mui/accordion" replace />, index: true },
                { path: 'accordion', element: <MUIAccordionPage /> },
                { path: 'alert', element: <MUIAlertPage /> },
                { path: 'autocomplete', element: <MUIAutocompletePage /> },
                { path: 'avatar', element: <MUIAvatarPage /> },
                { path: 'badge', element: <MUIBadgePage /> },
                { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
                { path: 'buttons', element: <MUIButtonsPage /> },
                { path: 'checkbox', element: <MUICheckboxPage /> },
                { path: 'chip', element: <MUIChipPage /> },
                { path: 'data-grid', element: <MUIDataGridPage /> },
                { path: 'dialog', element: <MUIDialogPage /> },
                { path: 'list', element: <MUIListPage /> },
                { path: 'menu', element: <MUIMenuPage /> },
                { path: 'pagination', element: <MUIPaginationPage /> },
                { path: 'pickers', element: <MUIPickersPage /> },
                { path: 'popover', element: <MUIPopoverPage /> },
                { path: 'progress', element: <MUIProgressPage /> },
                { path: 'radio-button', element: <MUIRadioButtonsPage /> },
                { path: 'rating', element: <MUIRatingPage /> },
                { path: 'slider', element: <MUISliderPage /> },
                { path: 'stepper', element: <MUIStepperPage /> },
                { path: 'switch', element: <MUISwitchPage /> },
                { path: 'table', element: <MUITablePage /> },
                { path: 'tabs', element: <MUITabsPage /> },
                { path: 'textfield', element: <MUITextFieldPage /> },
                { path: 'timeline', element: <MUITimelinePage /> },
                { path: 'tooltip', element: <MUITooltipPage /> },
                { path: 'transfer-list', element: <MUITransferListPage /> },
                { path: 'tree-view', element: <MUITreesViewPage /> },
              ],
            },
            {
              path: 'extra',
              children: [
                { element: <Navigate to="/components/extra/animate" replace />, index: true },
                { path: 'animate', element: <DemoAnimatePage /> },
                { path: 'carousel', element: <DemoCarouselsPage /> },
                { path: 'chart', element: <DemoChartsPage /> },
                { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
                { path: 'editor', element: <DemoEditorPage /> },
                { path: 'form-validation', element: <DemoFormValidationPage /> },
                { path: 'image', element: <DemoImagePage /> },
                { path: 'label', element: <DemoLabelPage /> },
                { path: 'lightbox', element: <DemoLightboxPage /> },
                { path: 'map', element: <DemoMapPage /> },
                { path: 'mega-menu', element: <DemoMegaMenuPage /> },
                { path: 'multi-language', element: <DemoMultiLanguagePage /> },
                { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
                { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
                { path: 'scroll', element: <DemoScrollbarPage /> },
                { path: 'snackbar', element: <DemoSnackbarPage /> },
                { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
                { path: 'upload', element: <DemoUploadPage /> },
                { path: 'markdown', element: <DemoMarkdownPage /> },
              ],
            },
          ],
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: 'pricing', element: <PricingPage /> },
        { path: 'payment', element: <PaymentPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: 'restriction', element: <PageRestrict /> },        
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    
  ]);
}
